import { Tooltip } from "@fluentui/react-components";
import { Sparkle28Filled } from "@fluentui/react-icons";

import styles from "./Answer.module.css";
import i18next from "../../i18n/i18n";
import { appInfo, userInfo } from "../../authConfig";

interface Props {
    apptype: string | null;
    language: string | null;
    product: string | null;
    sub_product: string | null;
    documenttype?: string | null;
    chatModel: string | null;
    feedbackCorpId: string | null;
    feedbackUser: string | null;
    CorpId: string | null | undefined;
    UserId: string | null | undefined;
    isFeedbackPage: boolean | undefined;
    productVersion: string | null;
    versionIndex: string | null;
    faqVersion: string | null;
}

export const AnswerIcon = ({
    apptype,
    language,
    product,
    sub_product,
    documenttype,
    chatModel,
    feedbackCorpId,
    feedbackUser,
    CorpId,
    UserId,
    isFeedbackPage,
    productVersion,
    versionIndex,
    faqVersion
}: Props) => {
    const tooltipArr = [];
    const selectedLocale = sessionStorage.getItem("selectedLocale") ?? "ja";
    const selected_product =
        apptype === "Manuals"
            ? appInfo?.product_manual_categories[selectedLocale]?.filter((item: any) => item.key === product)[0]
            : appInfo?.faq_categories[selectedLocale]?.filter((item: any) => item.key === product)[0];
    product = selected_product?.text ?? "";
    const langType = apptype === "Manuals" ? i18next.t("chat.manuals") : i18next.t("chat.faq");
    if (apptype) {
        tooltipArr.push(i18next.t("chat.apptype") + ": " + langType);
    }
    if (language) {
        tooltipArr.push(i18next.t("chat.language") + ": " + language);
    }
    if (product) {
        tooltipArr.push(i18next.t("chat.productFilterTitle") + ": " + product);
    }
    if (apptype === "Manuals" && productVersion) {
        const selectedVersion = selected_product?.versions?.filter((item: any) => item.key === productVersion)[0];
        const versions = productVersion === "0" ? "ALL" : selectedVersion?.text ?? "";
        tooltipArr.push(i18next.t("chat.version") + ": " + versions);
        if (versionIndex) {
            const selectedSubVersion = selectedVersion?.sub_versions?.filter((item: any) => item.key === versionIndex)[0];
            const sub_versions = versionIndex === "0" ? "ALL" : selectedSubVersion?.text ?? "";
            tooltipArr.push(i18next.t("chat.sub_version") + ": " + sub_versions);
        }
    }
    if (apptype === "FAQ") {
        if (selected_product?.versions && faqVersion) {
            const selectedVersion = selected_product?.versions?.filter((item: any) => item.key === faqVersion)[0];
            const versions = faqVersion === "0" ? "ALL" : selectedVersion?.text ?? "";
            tooltipArr.push(i18next.t("chat.version") + ": " + versions);
            if (sub_product) {
                const selectedSubVersion = selectedVersion?.sub_versions?.filter((item: any) => item.key === sub_product)[0];
                const sub_versions = sub_product === "0" ? "ALL" : selectedSubVersion?.text ?? "";
                tooltipArr.push(i18next.t("chat.subProduct") + ": " + sub_versions);
            }
        } else {
            const sub_product_list = selected_product?.data ?? [];
            const subProduct = sub_product === "0" ? "ALL" : sub_product_list?.filter((item: any) => item.key === sub_product)[0]?.text ?? "";
            if (subProduct) {
                tooltipArr.push(i18next.t("chat.subProduct") + ": " + subProduct);
            }
        }
    }
    if (userInfo.isWap == "true" || userInfo.isWap == "True") {
        if (documenttype) {
            tooltipArr.push(i18next.t("chat.documenttype") + ": " + i18next.t(`chat.${documenttype}`));
        }
        if (chatModel) {
            tooltipArr.push(i18next.t("chat.chatModel") + ": " + chatModel);
        }
    }
    if (feedbackCorpId) {
        tooltipArr.push(i18next.t("chat.feedbackCorpId") + ": " + feedbackCorpId);
    }
    if (feedbackUser) {
        tooltipArr.push(i18next.t("chat.feedbackuser") + ": " + feedbackUser);
    }
    if (isFeedbackPage) {
        if (CorpId) {
            tooltipArr.push(i18next.t("chat.corpId") + ": " + CorpId);
        }
        if (UserId) {
            tooltipArr.push(i18next.t("chat.userId") + ": " + UserId);
        }
    }
    return (
        <Tooltip
            content={{
                children: tooltipArr.join(", "),
                className: styles.answerIconTooltip
            }}
            relationship="label"
        >
            <Sparkle28Filled primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Answer logo" />
        </Tooltip>

        // text can be enabled instead of the tooltip above:
        // <div className={`${styles.answerIconText}`}>
        //     <Sparkle28Filled primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Answer logo" />
        //     {product ? (
        //         <span>
        //             {i18next.t("chat.productFilterTitle")}: {product}
        //         </span>
        //     ) : null}
        //     {product && chatModel ? <span>; </span> : null}
        //     {chatModel ? (
        //         <span>
        //             {i18next.t("chat.chatModel")}: {chatModel}
        //         </span>
        //     ) : null}
        // </div>
    );
};
