import { useMemo } from "react";
import { mergeStyleSets, Stack, IconButton, Popup, Layer, Overlay, FocusTrapZone, Checkbox, DefaultButton, Icon, Text } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import i18next from "../../i18n/i18n";
import { getCitationFilePath, ChatAppResponse, FeedbackResponse, saveSharedChatValueApi } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useBoolean } from "@fluentui/react-hooks";
import { TextField } from "@fluentui/react/lib/TextField";
import { LockIcon } from "../LockIcon/LockIcon";
import { Toast, ToastTitle, Toaster, useId, useToastController } from "@fluentui/react-components";
import { userInfo } from "../../authConfig";
import { useEffect } from "react";
interface Props {
    answer: ChatAppResponse;
    isStreaming: boolean;
    isSelected?: boolean;
    onCitationClicked: (filePath: string, citationIndex: number) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    isHistory?: boolean;
    isAdminUser?: boolean;
    doctype?: boolean;
    displayLink?: () => void;
    isLastAnswer: boolean;
    isFeedbackPage: boolean;
}

const popupStyles = mergeStyleSets({
    root: {
        background: "rgba(0, 0, 0, 0.5)",
        bottom: "0",
        left: "0",
        position: "absolute",
        right: "0",
        top: "0"
    },
    content: {
        background: "white",
        left: "50%",
        maxWidth: "1000px",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        border: "2px solid",
        borderColor: "grey",
        padding: "15px"
    },
    thumbTextBox: {
        padding: "5px",
        width: "700px",
        borderRadius: "10px"
    }
});

const stackTokens = { childrenGap: 10, padding: 25 };

export const AnswersHistory = ({
    answer,
    doctype,
    isStreaming,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    isHistory,
    isAdminUser,
    displayLink,
    isLastAnswer,
    isFeedbackPage
}: Props) => {
    const followupQuestions = answer.choices[0].context.followup_questions;
    const dataPoints = answer?.choices[0]?.context?.data_points;
    const messageContent = answer.choices[0].message.content;
    const fileMapping = answer?.choices[0]?.context?.file_mapping;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked, dataPoints, fileMapping), [answer]);
    const feedbacks: FeedbackResponse = answer.choices[0].context.feedback ?? { status: false, data: { text: "", type: 0, time: "" } };
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const [isLikePopupVisible, { setTrue: showLikePopup, setFalse: hideLikePopup }] = useBoolean(false);
    const [isDisLikePopupVisible, { setTrue: showDisLikePopup, setFalse: hideDisLikePopup }] = useBoolean(false);
    const [isSharChatPopupVisible, { setTrue: showSharChatPopup, setFalse: hideShareChatPopup }] = useBoolean(false);

    function copyToClipboard(href: string) {
        // Add UTM parameters to the chat share link
        const url = new URL(href);
        url.searchParams.set("utm_source", "aiworks");
        url.searchParams.set("utm_medium", "share_link");
        url.searchParams.set("utm_campaign", "chat_share");
        const chatShareLink = url.toString();
        const tempInput = document.createElement("input");
        tempInput.value = chatShareLink;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const notify = () =>
        dispatchToast(
            <Toast style={{ backgroundColor: "white", borderRadius: "5px", color: "green", width: "300px" }}>
                <ToastTitle style={{ color: "black", fontWeight: "bold" }}>{i18next.t("answer.chatLinkCopied")}</ToastTitle>
            </Toast>,
            { intent: "success" }
        );
    const saveSharedChatValue = async (sessionValue: string) => {
        try {
            await saveSharedChatValueApi(sessionValue, userInfo.corp_id)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    console.log(result);
                });
        } catch (error) {
            console.error(error);
        }
    };

    function parseQueryParams(queryvalue: string) {
        const queryparameter = new URLSearchParams(queryvalue);
        const params: { [key: string]: string } = {};

        for (const [key, value] of queryparameter.entries()) {
            params[key] = value;
        }

        return params;
    }
    const condition = feedbacks["category"] && feedbacks["category"] === 2;
    useEffect(() => {
        if (condition) {
            displayLink ? displayLink() : null;
        }
    }, [answer]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Stack
                className={`${styles.answerContainer} ${isSelected && styles.selected}`}
                verticalAlign="space-between"
                style={doctype ? { backgroundColor: "rgb(240, 255, 240)" } : {}}
            >
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <div>
                            <AnswerIcon
                                apptype={answer.choices[0].context.app_type}
                                language={answer.choices[0].context.language}
                                product={answer.choices[0].context.product_filter}
                                sub_product={answer.choices[0].context.sub_product_filter}
                                documenttype={answer.choices[0].context.document_category}
                                chatModel={answer.choices[0].context.chat_model}
                                feedbackCorpId={answer.choices[0].context.feedback_corp_id}
                                feedbackUser={answer.choices[0].context.feedback_user}
                                CorpId={answer.choices[0].context.corpId}
                                UserId={answer.choices[0].context.userId}
                                isFeedbackPage={isFeedbackPage}
                                productVersion={answer.choices[0].context.productVersion}
                                versionIndex={answer.choices[0].context.versionIndex}
                                faqVersion={answer.choices[0].context.faqVersion}
                            />
                            {doctype && <LockIcon />}
                        </div>
                        <div>
                            {isAdminUser && (
                                <IconButton
                                    style={{ color: "black" }}
                                    iconProps={{ iconName: "Lightbulb" }}
                                    title={i18next.t("answer.thoughtProcess")}
                                    ariaLabel={i18next.t("answer.thoughtProcess")}
                                    onClick={() => onThoughtProcessClicked()}
                                    disabled={!answer.choices[0].context.thoughts?.length}
                                />
                            )}
                            {parsedAnswer?.citations?.length > 0 && (
                                <IconButton
                                    style={{ color: "black" }}
                                    iconProps={{ iconName: "ClipboardList" }}
                                    title={i18next.t("answer.supportingContent")}
                                    ariaLabel={i18next.t("answer.supportingContent")}
                                    onClick={() => onSupportingContentClicked()}
                                    disabled={!answer.choices[0].context.data_points?.length}
                                />
                            )}
                        </div>
                    </Stack>
                </Stack.Item>

                <Stack.Item grow>
                    <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                </Stack.Item>

                {!!parsedAnswer.citations.length && answer.choices[0].context?.app_type && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>{i18next.t("answer.citations")}:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePath(x);
                                const contentPath = x
                                    .replace(/^product-manuals-\d+\//, "product-manuals/")
                                    .replace(/^product-manuals\/[^\/]+\//, "product-manuals/");
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path, i)}>
                                        {`${++i}. ${contentPath.replace(
                                            /^[^/]*\//,
                                            answer.choices[0].context.app_type === "Manuals" ? "product_manual/" : "faq/"
                                        )}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}

                {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                    <Stack.Item>
                        <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            <span className={styles.followupQuestionLearnMore}>{i18next.t("answer.followupQuestions")}:</span>
                            {followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
                {feedbacks["status"] && feedbacks["status"] === true && (
                    <Stack.Item>
                        <Stack horizontal horizontalAlign="end">
                            <IconButton
                                style={{ color: "black" }}
                                iconProps={{ iconName: "Share" }}
                                title="Share"
                                ariaLabel="ShareChatURL"
                                onClick={() => showSharChatPopup()}
                            />
                            {feedbacks["category"] === 1 && (
                                <IconButton
                                    id="icon_button_like"
                                    style={{ color: "black" }}
                                    iconProps={{ iconName: "LikeSolid" }}
                                    title={i18next.t("answer.good")}
                                    ariaLabel={i18next.t("answer.good")}
                                    onClick={() => {
                                        showLikePopup();
                                    }}
                                />
                            )}
                            {feedbacks["category"] === 2 && (
                                <IconButton
                                    id="icon_button_dislike"
                                    style={{ color: "black" }}
                                    iconProps={{ iconName: "DislikeSolid" }}
                                    title={i18next.t("answer.bad")}
                                    ariaLabel={i18next.t("answer.bad")}
                                    onClick={() => {
                                        showDisLikePopup();
                                    }}
                                />
                            )}
                        </Stack>
                    </Stack.Item>
                )}
                {answer.choices[0].context.feedbacktype != undefined && (
                    <Stack.Item>
                        <Stack horizontal horizontalAlign="end">
                            <IconButton
                                style={{ color: "black" }}
                                iconProps={{ iconName: "Share" }}
                                title={i18next.t("chat.shareChat")}
                                ariaLabel={i18next.t("chat.shareChatURL")}
                                onClick={() => showSharChatPopup()}
                            />
                            {answer.choices[0].context.feedbacktype === "Positive" && (
                                <IconButton
                                    id="icon_button_like"
                                    style={{ color: "rgb(161, 159, 157)" }}
                                    iconProps={{ iconName: "LikeSolid" }}
                                    title={i18next.t("answer.good")}
                                    ariaLabel={i18next.t("answer.good")}
                                />
                            )}
                            {answer.choices[0].context.feedbacktype === "Negative" && (
                                <IconButton
                                    id="icon_button_dislike"
                                    style={{ color: "rgb(161, 159, 157)" }}
                                    iconProps={{ iconName: "DislikeSolid" }}
                                    title={i18next.t("answer.bad")}
                                    ariaLabel={i18next.t("answer.bad")}
                                />
                            )}
                        </Stack>
                    </Stack.Item>
                )}
                {isSharChatPopupVisible && (
                    <Layer>
                        <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hideShareChatPopup}>
                            <FocusTrapZone>
                                <div role="document" className={popupStyles.content} style={{ width: "500px" }}>
                                    <div style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                        <Icon iconName="share" style={{ padding: "5px", fontSize: "20px" }} />
                                        <span style={{ fontSize: "19px" }}>{i18next.t("answer.shareChat")}</span>
                                        <IconButton
                                            style={{ color: "black", float: "right" }}
                                            iconProps={{ iconName: "Cancel" }}
                                            onClick={hideShareChatPopup}
                                        />
                                    </div>
                                    <div className={styles.thumbTextBox} style={{ display: "grid", justifyContent: "center", width: "465px" }}>
                                        <label style={{ fontSize: "20px" }}>{i18next.t("answer.shareChatText")}</label>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <DefaultButton
                                            autoFocus
                                            iconProps={{ iconName: "link" }}
                                            style={{ color: "blue", borderRadius: "5px", padding: "5px", fontSize: "13px" }}
                                            onClick={() => {
                                                const queryParams = parseQueryParams(location.search);
                                                const sessionValue = queryParams.SessionId;
                                                copyToClipboard(window.location.href);
                                                hideShareChatPopup();
                                                notify();
                                                saveSharedChatValue(sessionValue);
                                            }}
                                            text={i18next.t("answer.copyLink")}
                                        ></DefaultButton>{" "}
                                    </div>
                                </div>
                            </FocusTrapZone>
                        </Popup>
                    </Layer>
                )}
                <Toaster toasterId={toasterId} position="bottom-start" pauseOnHover pauseOnWindowBlur timeout={2000} />
                {isLikePopupVisible && !isHistory && (
                    <Layer>
                        <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hideLikePopup}>
                            <Overlay onClick={hideLikePopup} />
                            <FocusTrapZone>
                                <div role="document" className={popupStyles.content}>
                                    <div>
                                        <IconButton style={{ color: "green" }} iconProps={{ iconName: "LikeSolid" }} />
                                        <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>{i18next.t("answer.chatFeedback")}</span>
                                        <IconButton style={{ color: "green", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={hideLikePopup} />
                                    </div>
                                    <div>
                                        <TextField
                                            className={popupStyles.thumbTextBox}
                                            multiline
                                            resizable={false}
                                            value={feedbacks["data"] && feedbacks["data"]["text"]}
                                        />
                                    </div>
                                </div>
                            </FocusTrapZone>
                        </Popup>
                    </Layer>
                )}

                {isDisLikePopupVisible && !isHistory && (
                    <Layer>
                        <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hideDisLikePopup}>
                            <Overlay onClick={hideDisLikePopup} />
                            <FocusTrapZone>
                                <div role="document" className={popupStyles.content}>
                                    <div>
                                        <IconButton style={{ color: "red" }} iconProps={{ iconName: "DisLikeSolid" }} />
                                        <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>{i18next.t("answer.chatFeedback")}</span>
                                        <IconButton style={{ color: "green", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={hideDisLikePopup} />
                                    </div>
                                    <div>
                                        <TextField
                                            className={popupStyles.thumbTextBox}
                                            multiline
                                            resizable={false}
                                            autoAdjustHeight
                                            value={feedbacks["data"] && feedbacks["data"]["text"]}
                                        />
                                        <Stack tokens={stackTokens} style={{ padding: "5px 10px 10px 15px" }}>
                                            <Checkbox
                                                label={i18next.t("answer.notTrue")}
                                                checked={feedbacks["data"] && feedbacks["data"]["type"] === 1}
                                                disabled={true}
                                            />
                                            <Checkbox
                                                label={i18next.t("answer.notHelpful")}
                                                checked={feedbacks["data"] && feedbacks["data"]["type"] === 2}
                                                disabled={true}
                                            />
                                            <Checkbox
                                                label={i18next.t("answer.harmful")}
                                                checked={feedbacks["data"] && feedbacks["data"]["type"] === 3}
                                                disabled={true}
                                            />
                                        </Stack>
                                    </div>
                                </div>
                            </FocusTrapZone>
                        </Popup>
                    </Layer>
                )}
            </Stack>
            {isLastAnswer && <Text className={styles.lastInfo}>{i18next.t("chat.responseInfo")}</Text>}
        </div>
    );
};
