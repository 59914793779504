import { configureStore } from "@reduxjs/toolkit";
import TemplateContents from "./TemplateContents";

const store = configureStore({
    reducer: {
        templateContents: TemplateContents
    }
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
