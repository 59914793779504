import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};

type DataPoint = {
    sourcepage_field: string;
    uploaddir_field: string;
};

export function parseAnswerToHtml(
    answer: string,
    isStreaming: boolean,
    onCitationClicked: (citationFilePath: string, citationIndex: number) => void,
    dataPoints: DataPoint[],
    file_mapping: any
): HtmlParsedAnswer {
    const citations: string[] = [];

    // trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim();
    if (file_mapping) {
        Object.keys(file_mapping).forEach(key => {
            const regex = new RegExp(`[${key}]`, "g");
            if (parsedAnswer.includes(`[${key}]`)) {
                parsedAnswer = parsedAnswer.replace(`${key}`, file_mapping[key]);
            } else if (parsedAnswer.includes(`${key}`)) {
                const regex = new RegExp(key, "g");
                parsedAnswer = parsedAnswer.replace(`${key}`, `[${file_mapping[key]}]`);
            }
        });
    }

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

    const validCitations = new Set(dataPoints.flatMap(item => [`${item.uploaddir_field}/${item.sourcepage_field}`, `${item.sourcepage_field}`]));

    const replaceTempfileWithActual = (part: string): string => {
        return part.replace(/tempfile\d+\.(pdf|txt)/g, match => file_mapping[match] || match);
    };
    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            // Check if the citation is valid
            part = replaceTempfileWithActual(part);
            if (validCitations.has(part)) {
                let citationIndex: number;
                if (citations.indexOf(part) !== -1) {
                    citationIndex = citations.indexOf(part) + 1;
                } else {
                    citations.push(part);
                    citationIndex = citations.length;
                }

                const path = getCitationFilePath(part);

                return renderToStaticMarkup(
                    <a className="supContainer" title={part} onClick={() => onCitationClicked(path, citationIndex)}>
                        <sup>{citationIndex}</sup>
                    </a>
                );
            } else {
                // If not valid, add the citation back to the answer with brackets
                return `[${part}]`;
            }
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
}
