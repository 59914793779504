import { createSlice } from "@reduxjs/toolkit";

const TemplateContents = createSlice({
    name: "templateContents",
    initialState: {
        templateContents: [],
        templateTitles: []
    },
    reducers: {
        setTemplateContentsInStore: (state, actions) => {
            state.templateContents = actions.payload;
            state.templateTitles = actions.payload.map((template: any) => template.template[0].title);
        }
    }
});

export default TemplateContents.reducer;
export const { setTemplateContentsInStore } = TemplateContents.actions;
