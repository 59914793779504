import { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { BookTemplate20Filled, Send28Filled } from "@fluentui/react-icons";
import i18next from "../../i18n/i18n";
import styles from "./QuestionInput.module.css";
import { MentionsInput, Mention } from "react-mentions";
import MentionStyle from "./MentionStyle";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { styled, Zoom } from "@mui/material";
interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    onTemplateIconClick?: () => void;
    templateContent?: any;
    onClearTemplateContent?: () => void;
    disableTextarea: boolean;
    productIndex: string | undefined;
    isAdmin: boolean;
    isEdited: boolean;
    setIsEdited: (isEdited: boolean) => void;
}

export const QuestionInput = ({
    onSend,
    disabled,
    placeholder,
    clearOnSend,
    onTemplateIconClick,
    templateContent,
    onClearTemplateContent,
    disableTextarea,
    productIndex,
    isAdmin,
    isEdited,
    setIsEdited
}: Props) => {
    const [question, setQuestion] = useState<string>("");

    const templateContents = useSelector((store: RootState) => store.templateContents.templateContents);
    const templateTitles = useSelector((store: RootState) => store.templateContents.templateTitles);

    useEffect(() => {
        if (!isEdited) {
            setQuestion(templateContent.previewPrompt ?? "");
        }
    }, [templateContent, isEdited]);

    const formattedTemplateTitles = templateTitles.slice(0, 5).map(title => ({
        id: title,
        display: title
    }));

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        if (!isAdmin && (productIndex === "0" || !productIndex)) {
            onSend("invalid_selection");
            return;
        }
        let filteredQuestion = question.match(/@\[(.*?)\]/)?.[1];

        onSend(filteredQuestion ? filteredQuestion : question);

        if (clearOnSend) {
            setQuestion("");
            setIsEdited(false);
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (disabled) {
            return;
        }
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
            setIsEdited(false);
            if (onClearTemplateContent) {
                onClearTemplateContent();
            }
        }
    };

    const onQuestionChange = (newValue?: string) => {
        if (!newValue) {
            setQuestion("");
            setIsEdited(false);
            if (onClearTemplateContent) {
                onClearTemplateContent();
            }
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
            setIsEdited(true);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    const handleTemplateIcon = () => {
        if (onTemplateIconClick) {
            onTemplateIconClick();
        }
    };

    const handleMentionSelect = (_id: string | number, display: string) => {
        let previewPrompt: any = templateContents.find((content: any) => content.template[0].title.includes(display));
        setQuestion(previewPrompt.template[0].previewPrompt);
    };

    const renderMentionSuggestion = (entry: any, search: string, highlightedDisplay: React.ReactNode) => {
        const content: any = templateContents.find((template: any) => template.template[0].title === entry.display);
        const previewPrompt = content?.template[0].previewPrompt ?? "No preview available";
        const formattedPreviewPrompt = previewPrompt.replace(/\n/g, "<br />");
        return (
            <LightTooltip
                content={previewPrompt}
                title={<span dangerouslySetInnerHTML={{ __html: formattedPreviewPrompt }} />}
                followCursor
                TransitionComponent={Zoom}
                placement="right-end"
            >
                <div>{highlightedDisplay}</div>
            </LightTooltip>
        );
    };

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11
        }
    }));

    return (
        <div style={{ display: "flex", width: "100%", alignItems: "flex-end" }}>
            {isAdmin && (
                <Stack style={{ position: "relative", flexShrink: 0 }}>
                    <LightTooltip title={i18next.t("template.templatePromptIcon")} placement="top" TransitionComponent={Zoom}>
                        <Button
                            id="TemplateIcon"
                            size="large"
                            icon={
                                <div className={styles.templateIcon}>
                                    <BookTemplate20Filled primaryFill="red" onClick={handleTemplateIcon} />
                                </div>
                            }
                            disabled={disableTextarea || (!isAdmin && (productIndex === "0" || productIndex === ""))}
                            style={{ marginBottom: "1vh", paddingRight: "1vw" }}
                        />
                    </LightTooltip>
                </Stack>
            )}
            <Stack horizontal className={styles.questionInputContainer} style={{ background: disableTextarea ? "#F2F2F2" : "#fff" }}>
                <div style={{ width: "100%", height: "100%" }}>
                    <MentionsInput
                        id="question-input"
                        className={styles.questionInputTextArea}
                        placeholder={placeholder}
                        value={isEdited ? question : templateContent.previewPrompt ?? ""}
                        onChange={e => onQuestionChange(e.target.value)}
                        onKeyDown={onEnterPress}
                        disabled={disableTextarea}
                        autoFocus
                        style={MentionStyle}
                        forceSuggestionsAboveCursor={true}
                        allowSuggestionsAboveCursor={true}
                    >
                        <Mention trigger="/" data={formattedTemplateTitles} onAdd={handleMentionSelect} renderSuggestion={renderMentionSuggestion} />
                    </MentionsInput>
                    {isAdmin && (
                        <h6 style={{ float: "right", marginRight: "1%", marginBottom: "1.4%", marginTop: "1%" }}> {i18next.t("template.quickSearch")} </h6>
                    )}
                </div>
                <div className={styles.questionInputButtonsContainer}>
                    <LightTooltip title={i18next.t("questionInput.tooltip")} placement="top">
                        <Button
                            id="sendButton"
                            size="large"
                            icon={<Send28Filled primaryFill="rgba(115, 118, 225, 1)" />}
                            disabled={sendQuestionDisabled}
                            onClick={sendQuestion}
                        />
                    </LightTooltip>
                </div>
            </Stack>
        </div>
    );
};
